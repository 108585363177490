import Link from 'next/link';

import { Background } from '../components/background/Background';
import { Button } from '../components/button/Button';
import { Section } from '../components/layout/Section';
import { NavbarMenuCenter } from '../components/navigation/NavbarMenuCenter';
import { Logo } from './Logo';

interface IProps {
  sections?: string[];
}

const Navbar = ({ sections = ['overview'] }: IProps) => {
  return (
    <Background>
      <Section yPadding="py-6">
        <NavbarMenuCenter
          logo={<Logo xl />}
          rightMenu={
            <div className="flex flex-col items-center justify-between font-medium text-black md:w-52 md:flex-row">
              <li className="w-full pb-3 md:w-auto md:pb-0">
                <Link href={'/share-programs'} className="block p-4 text-center md:inline md:p-0">
                  Sign In
                </Link>
              </li>
              <li className="">
                <Link href="/get-started">
                  {/* button not rendering as button */}
                  <Button>Try For Free</Button>
                </Link>
              </li>
            </div>
          }
        >
          <div className="w-100 flex justify-items-end font-medium text-black">
            {sections.includes('overview') && (
              <li className="block w-full">
                <Link href="#overview" className="block p-4 text-center">
                  Platform Overview
                </Link>
              </li>
            )}
          </div>
          {/* <li>
          <Link href="/about">About</Link>
        </li> */}
          {/* <li>
          <Link href="/">Treatments</Link>
        </li> */}
        </NavbarMenuCenter>
      </Section>
    </Background>
  );
};

export { Navbar };
