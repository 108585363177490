export const AppConfig = {
  code: 'platform',
  api_url: process.env.NEXT_PUBLIC_API_URL || 'https://api.lifeisachance.com',
  graphql_url: process.env.NEXT_PUBLIC_GRAPHQL_URL || 'https://api.lifeisachance.com/graphql',
  config_note_id: process.env.NEXT_PUBLIC_CONFIG_NOTE_ID || '65',
  site_name: 'Weller Labs',
  title: 'A treatment development platform for regulated digital treatments.',
  description:
    'Weller Labs is a treatment development platform for regulated digital treatments. We help you build, test, and launch your digital treatment.',
  locale: 'en',
};
